/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: highChartsTranslationMixin.js
Description: This mixin contains the functionality related to the translations for a highcharts
*/
import Highcharts from "highcharts";
import filtersMixin from "@/mixins/filtersMixin";
import resizeContainerMixin from "@/mixins/resizeContainerMixin";
const highChartsTranslationMixin = {
  mixins: [filtersMixin, resizeContainerMixin],
  computed: {
    hctm__getCurrLang() {
      return this.$i18n.locale;
    }
  },
  watch: {
    hctm__getCurrLang: function(newvalue, oldvalue) {
      this.initChartLang();
      this.setAxisText();
      this.initChartSeries();
    }
  },
  data: function() {
    return {
      hctm__xAxisTextKey: "Comn_feed",
      hctm__yAxisTextKey: "Comn_fcr"
    };
  },
  beforeCreate() {
    if (this != null) {
      const lang = {
        loading: this.$t("Comn_loading_pls_wait"),
        noData: this.$t("Comn_no_data_connection"),
        numericSymbolMagnitude: 1000,
        rangeSelectorZoom: this.$t("Comn_zoom"),
        resetZoom: this.$t("Comn_reset_zoom"),
        resetZoomTitle: this.$t("Comn_reset_zoom_1_1")
      };
      Highcharts.setOptions({ lang });
    }
  },
  methods: {
    hctm__initChartStrings() {
      const lang = {
        loading: this.$t("Comn_loading_pls_wait"),
        noData: this.$t("Comn_no_data_connection"),
        numericSymbolMagnitude: 1000,
        rangeSelectorZoom: this.$t("Comn_zoom"),
        resetZoom: this.$t("Comn_reset_zoom"),
        resetZoomTitle: this.$t("Comn_reset_zoom_1_1")
      };
      Highcharts.setOptions({ lang });
    },
    hctm__initChartSeries() {},
    hctm__initChartLang() {
      this.initChartStrings();
    },
    hctm__setAxisText() {
      this.chartOptions.xAxis.title.text = this.ftm__capitalize(
        this.$t(this.hctm__xAxisTextKey)
      );
      this.chartOptions.yAxis.title.text = this.ftm__capitalize(
        this.$t(this.hctm__yAxisTextKey)
      );
    },
    hctm__initAxisTextKeys(hctm__xAxisTextKey, hctm__yAxisTextKey) {
      this.hctm__xAxisTextKey = hctm__xAxisTextKey;
      this.hctm__yAxisTextKey = hctm__yAxisTextKey;
    }
  }
};
export default highChartsTranslationMixin;
