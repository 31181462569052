import {
  baseChartConfig as baseGraphConfig,
  xAxisConfig
} from "@/config/baseChartConfig";

export const ssABWDistributionGraph = {
  ...baseGraphConfig,
  xAxis: xAxisConfig({
    type: "category",
    tickmarkPlacement: "on",
    crosshair: false,
    ordinal: false
  }),
  tooltip: {
    useHTML: true,
    valueDecimals: 2,
    formatter: function() {
      const series = this.points[0].series;
      const point = this.points[0].point;
      console.log(point);
      return `
      <table>
       <tr>
        <td style='color:#000000'>
            ${series.name.xAxis}:
        </td>
        <td style='text-align: right'>  
          ${point.tooltip_x_axis}
        </td>
      </tr>
      <tr>
        <td style='color:#000000; text-align:right;'>
            ${series.name.yAxis}:
        </td>
        <td style='text-align: right'>  
          ${point.y}
        </td>
      </tr>
      </table>`;
    }
  }
};

export default {
  ssABWDistributionGraph
};
